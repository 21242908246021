export const saleTypes = {
    'en': [{
        title: 'For Sale',
        heroTitle: 'Buy',
        value: 'sale'
    },
    {
        title: 'For Rent',
        heroTitle: 'Rent',
        value: 'rent'
    }],
    'th': [{
        title: 'ซื้อ',
        heroTitle: 'Buy',
        value: 'sale'
    },
    {
        title: 'เช่า',
        heroTitle: 'Rent',
        value: 'rent'
    }],
    'cn': [{
        title: '买房',
        heroTitle: 'Buy',
        value: 'sale'
    },
    {
        title: '租房',
        heroTitle: 'Rent',
        value: 'rent'
    }]
}
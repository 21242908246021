'use client'
import React, { useState, useEffect, useRef } from 'react'
import { useSession } from 'next-auth/react'
import Image from 'next/image'
import { AiOutlineLayout, AiFillHeart, AiOutlineHeart } from "react-icons/ai"
import { BiSolidBed, BiSolidBath } from "react-icons/bi"
import tailwindConfig from '@tailwind.config'
import { sendLike } from '@services/sendLike'
import CurrencySymbol from '@components/CurrencySymbol'
import { categoryTypes } from '@constants'
import { MdVilla } from "react-icons/md";
import { FaBuilding } from "react-icons/fa";
import LinkComponent from './LinkComponent'


const BlogCard = ({ item, url='/', imageWidth = 300, imageHeight = 200, newLimit, isLast, dictionary, lang }) => {
    const [showLikeBtn, setShowLikeBtn] = useState(false)
    const { data: session } = useSession()

    const cardRef = useRef();

    useEffect(() => {
        if (!cardRef?.current) return;

        const observer = new IntersectionObserver(([entry]) => {
            if (isLast && entry.isIntersecting) {
                newLimit();
                // nextPage()
                observer.unobserve(entry.target);
            }
        });

        observer.observe(cardRef.current);
    }, [isLast]);

    useEffect(() => {
        if (session && session.user !== null) {
            setShowLikeBtn(true)
        }
    }, [])

    return (
        <div ref={cardRef} className='relative w-full'>
            <LinkComponent
                className='w-full'
                href={'/blogs/' + item.slug}
                
            >
                <div className='relative w-[250px] h-[187.5px] rounded-md overflow-hidden'>
                    <Image
                        // placeholder="blur"
                        // blurDataURL={item.blur}
                        // className='object-fit'
                        className='object-cover'
                        src={item?.cloudThumbnail ? item.cloudThumbnail : '/images/test.jpg'}
                        // width={imageWidth}
                        // height={imageHeight}
                        fill={true}
                        alt={item.slug}
                    />
                </div>
                <div className=''>
                    <div className='text-xl font-semibold text-maincolor line-clamp-2'>{item?.title}</div>
                    <div className='h-[70px]'>
                        <p className='text-secondary overflow-clip line-clamp-3'>{item?.excerpt}</p>
                    </div>
                    <p className='text-mainhover'>{item.createdAt?.split('T')?.[0]}</p>
                </div>
            </LinkComponent >
        </div>
    )
}

export default BlogCard